.ws-content{
    width: 56px !important;
    height: 56px;
    position: fixed;
    z-index: 9;
    bottom: 20px;
    right: 10px;
    background-color: rgb(45, 183, 66);
    border-radius: 50%;
    box-shadow: 2px 2px 3px #999;
}

.ws-content svg {
    font-size: 30px;
    margin-top: 12px;
    color: #fff;
    margin-left: 2px;
}

@media(max-width: 700px) {
    .ws-content {
        bottom: 95px;
        right: 22px;
    }
    .ws-content-modal-open {
        bottom: 320 !important;
    }
}