body {
    background: #F7FAFC;
    font-family: 'Almarai', sans-serif;
    /* overflow-x: hidden; */
}

/* body,
a,
p,
span,
div{
    font-family: 'Selawik';
} */

.shadow-box {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border: none;
}

.mt-content {
    margin-top: 5.5rem !important;
}

.border-box {
    border-radius: 0px 8px 8px 8px;
}

.content-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.primary-bg-color {
    background-color: #044064 !important;
    border-color: #044064 !important;
}

.danger-bg-color {
    background-color: #FA0F00 !important;
    border-color: #FA0F00 !important;
}

.grey-bg-color {
    background-color: #E7E7E7;
}

.dark-grey-bg-color {
    background-color: #666666;
}

.info-bg-color {
    background-color: #249CF3;
    border-color: #249CF3;
}

.info-bg-color:hover {
    background-color: #249CF3;
    border-color: #249CF3;
}

.primary-bg-color:hover {
    background-color: #249CF3;
}

.primary-color {
    color: #044064;
}

.second-color {
    color: #249CF3;
}

.primary-color-text {
    color: #044064;
}

.primary-color:hover {
    color: #249CF3;
}

.logo-login img {
    width: 53%;
}

input,
button,
select {
    height: 45px;
}

input:focus {
    border-color: #249CF3 !important;
}

.card-login {
    position: relative;
}

.content-loader {
    height: auto;
    max-width: 800px;
    width: 800px;
    margin: 30px auto;
}

.title-stages {
    font-size: 20px;
    letter-spacing: 0.08em;
}

.link {
    text-decoration: none;
    background-color: transparent;
    color: inherit;
    border: none;
    width: 100%
}

.link-demo {
    text-decoration: none;
    background-color: transparent;
    color: inherit;
    border: none;
}

.container-box-demo {
    width: 90%;
    border-radius: 5;
    border: 1px solid #249CF3;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.20);
}

.logo-demo {
    width: 300px;
    height: 100px;
}

.header-box-demo {
    height: 40px;
    background-color: #04477A
}

.icon-box-demo {
    width: 20%;
    border-bottom: 1px solid #249CF3;
    border-left: 1px solid #249CF3;
}

.p-box-demo {
    width: 80%;
    border-bottom: 1px solid #249CF3;
}

.link-demo:hover {
    color: #000000
}

.btn-delete {
    width: 80px
}


@media(max-width: 700px) {
    .mt-content {
        margin: 0 !important;
        margin-top: 0 !important;
    }

    body {
        background-color: #fff;
    }

    .content-loader {
        width: 80%;
        margin: 30px auto;
    }
}

/*Login and terns & conditions*/
.emailLinkReset {
    color: white;
    background-color: transparent;
    text-decoration: none;
}

.emailLinkReset:hover {
    color: white;
    background-color: transparent;
    text-decoration: none;
}

.termsAndConditionsTitle {
    display: inline-block !important;
    padding-right: 0.5em;
    margin-bottom: 0%;
    text-align: center;
}

.termsAndConditionsTexts {
    text-align: justify;
    text-justify: inter-word;
}

.termsAndConditionsTextsSeparation p li {
    padding-bottom: 0.1em;
}

.termsAndConditionsTextsUl {
    list-style-type: none;
}

.inlineCheckbox {
    display: inline-flex !important;
    align-items: center !important;
    padding-left: 10px;
}

.inlineCheckboxLabel {
    padding-left: 10px;
    margin-left: 10px;
}


.close {
    color: #fff;
    opacity: 1;
}

/*Menu*/
.notifications-content-nav {
    visibility: hidden;
}

.navbar-container {
    height: 90px;
    width: 100%;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
}

.navbar-container-mobile {
    display: none;
}

.row-nav {
    width: 100%;
    margin: 0;
}

.nav-span a {
    text-decoration: none;
    margin-right: 60px;
}

.nav-span span {
    margin-left: 10px;
}

.nav-bar-footer-container {
    display: none;
}

@media(max-width: 912px) {
    .nav-span a {
        text-decoration: none;
        margin-right: 0;
        margin-left: 60px;
    }

}

@media(max-width: 700px) {
    .row-nav {
        overflow: hidden;
    }

    .navbar-container {
        display: none;
    }

    .navbar-container-mobile {
        display: block;
        padding: 25px 1.5rem;
    }

    .nav-bar-footer-container {
        display: block;
        position: fixed;
        bottom: 0;
        /* z-index: 9999; */
        width: 100%;
        background-color: #F7F7F7;
    }

    .navbar-content {
        padding-right: calc(1.5rem * .5);
        padding-left: calc(1.5rem * .5);
        display: flex;
    }

    .navbar-item-mobile {
        flex: 1 1 0px;
        margin-bottom: -10px;
        margin-top: -10px;
    }

    .navbar-item-mobile:last-child {
        margin-left: auto;
        order: 2;
        display: none;
    }

    .navbar-item-mobile p {
        margin: 0;
    }
}

/*Menu*/

/*icons*/
.heart-icon:hover .heart_p1 {
    fill: #00FFD4;
}

.heart-icon:hover .heart_p2 {
    fill: #469DEC;
}

.icon:hover path {
    fill: #469DEC;
}

.icon:hover .p_path1,
.icon:hover .p_path2 {
    fill: #469DEC;
    stroke: #469DEC;
}

.program-icon:hover path {
    stroke: #469DEC;
}

/* .active-icon {
    border-bottom: 6px solid #469DEC;
} */

.active-icon .heart_p1 {
    fill: #00FFD4;
}

.dark-opacity {
    background-color: rgba(0, 0, 0, 0.5);
}

.active-icon .heart_p2,
.active-icon .ex_path,
.active-icon .tp_path {
    fill: #469DEC;
}

.active-icon .p_path1,
.active-icon .p_path2 {
    fill: #469DEC;
    stroke: #469DEC;
}

.active-icon .pro_path {
    stroke: #469DEC;
}

/*icons*/


/*Notifications*/
.list-group-item {
    border: none;
    padding: 0.5rem 0.5rem;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 15px;
    padding-top: 15px;
    cursor: pointer;
}

.list-group-item span {
    display: block;
}

.list-group-item small {
    color: #A2A2A2;
}

.list-group-item:last-child {
    border: none;
}

.dropdown-menu {
    left: -22rem !important;
    margin: 0 !important;
    width: 400px;
    top: 3.5rem !important;
    padding: 0;
}

.content-notification-icon {
    position: relative;
}

.content-notification-icon small {
    position: absolute;
    left: 22px;
    border-radius: 2px;
    padding: 1px 3px;
    top: -4px;
}

@media(max-width: 700px) {
    .dropdown {
        position: inherit;
    }

    .dropdown-menu {
        position: absolute !important;
        width: auto !important;
        left: 0 !important;
        top: 4.5rem !important;
    }

    .msg-info span {
        font-size: 13px;
        padding-left: 5px;
    }

    .msg-info small {
        font-size: 11px;
        padding-left: 5px;
    }

    .comming-soon {
        line-height: .75;
    }
}

/*Notifications*/

/*TimeLines*/
.content-timeline-indicators {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.content-timeline-indicators .MuiStepConnector-line {
    border-top-width: 10px !important;
    margin-top: 7px;
    border-color: #044064 !important;
}

.content-timeline-program .item-active .MuiTimelineDot-root {
    background-color: #249CF3;
    border-color: #fff;
}

.content-timeline-program .item-complete .MuiTimelineDot-root {
    background-color: #044064;
}

.content-timeline-program .item-locked .MuiTimelineDot-root {
    background-color: #D9D9D9;
}


.content-timeline-indicators .step-select .MuiStepLabel-iconContainer {
    margin-top: -15px;
}

.content-timeline-program .MuiTimelineDot-root {
    border-width: 10px;
    background-color: #044064;
}

.content-timeline-program .MuiTimelineConnector-root {
    margin: -12px;
    width: 6px;
    height: 115px;
    background-color: #044064;

}

.content-timeline-program .item-locked .MuiTimelineConnector-root {
    background-color: #D9D9D9;
}

.content-timeline-program .MuiTimelineItem-root:before {
    flex: .6;
}

.info-benefit {
    display: flex;
}

.content-benefit p {
    padding-left: 15px;
    margin-bottom: 15px;
}

.btn-agenda {
    margin-left: 15px;
}

.status-benefit .complete-icon {
    margin-left: 10px;
}

@media(max-width: 700px) {
    .content-timeline-indicators .MuiStepConnector-root {
        left: calc(-50% + 11px);
        right: calc(50% + 11px);
    }

    .content-timeline-indicators .MuiStepConnector-line {
        margin-top: 0;
    }

    .content-timeline-indicators svg {
        transform: scale(0.7);
        -ms-transform: scale(0.7);
        -webkit-transform: scale(0.7);
        transform-origin: center center;
    }

    .content-timeline-program .MuiTimelineItem-root:before {
        flex: 0;
        padding: 0;
    }

    .content-benefit svg {
        transform: scale(0.9);
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform-origin: left;
    }

    .content-benefit p {
        padding-left: 7px;
    }

    .content-benefit .benefit {
        font-size: 15px;
    }

    .content-benefit .status-benefit {
        font-size: 14px;
    }

    .info-benefit {
        padding: 0;
    }
}

/*TimeLines*/
/*Sub Menu*/
.sub-options {
    position: absolute;
    top: -47px;
    left: 0px;
}

@media (max-width: 700px) {
    .sub-options {
        position: relative;
        top: 0px;
        left: 0px;
    }

    .sub-options .nsv {
        flex-wrap: inherit;
        display: flex;
    }

    .sub-options .nav-item {
        flex: 1 1 0px;
    }
}

/*Sub Menu*/
/*Indicadores*/
.box-content {
    text-align: center;
    box-shadow: 0px 12px 17px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 12px 17px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 12px 17px -4px rgba(0, 0, 0, 0.75);
}

.box-content-flex {
    flex-grow: 1;
    text-align: center;
    box-shadow: 0px 12px 17px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 12px 17px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 12px 17px -4px rgba(0, 0, 0, 0.75);
}

.cajas-responsive {
    margin-top: 20px;
}

.boxcajita {
    height: 80px;
    width: 120px;
    /* margin-right: 10px; */
    border: 1px solid #66afe9;
    /* border-bottom: 5px solid #66afe9; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}

.box-download-exam {
    cursor: pointer;
    box-shadow: 0px 12px 17px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 12px 17px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 12px 17px -4px rgba(0, 0, 0, 0.75);
}

.box-indicator-ecg {
    height: 96px;
    width: 250px;
    /* margin-right: 10px; */
    border: 1px solid #66afe9;
    /* border-bottom: 5px solid #66afe9; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}

.border-bottom-container-exam {
    height: 30px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0 auto;
    cursor: pointer;
    padding: 3px;
    /* bottom: 0; */
}

.col-img-stage {
    text-align: center;
}

.stages {
    width: 341px;
}

.indicators-content {
    width: 42%;
    margin: 0 auto;
    /* border: 1px solid red */
}
.meta-indicators-content-width {
    width: 44%;
}
.indicators-title {
    font-weight: 700;
    font-size: 18px;
}


.determination-content {
    line-height: 1.5;
}

.title-physical-exam {
    font-size: 10px;
    line-height: 17px;
}

.determination-content p {
    margin: 0;
    padding-left: 15px;
    font-size: 13px;
}

.indicator-result p {
    margin: 0;
    padding-left: 0px;
}

.rcv-info-status {
    margin-top: -120px;
}

.rcv-info-status p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.small-score-icon {
    display: none;
}

.rcv-alto {
    margin-left: 27px;
}

.rcv-bajo {
    margin-left: -57px;
}

.indicator-reddit {
    border-right: 17px solid #fa6200;
}

.indicator-danger {
    background-color: #FA0F00;
}

.indicator-warning-modal {
    background-color: #ffcc15;
}

.indicator-success {
    background-color: #00A341;
}

.indicator-bg-reddit {
    background-color: #fa6200;
}

.indicator-bg-danger {
    background-color: #FA0F00;
}

.indicator-bg-warning {
    background-color: #ffcc15;
}

.indicator-bg-success {
    background-color: #00A341;
}

.indicator-white {
    background-color: #ffffff;
}

.indicator-status-grey {
    background-color: #A1A1A1;
}

.border-under {
    border-bottom: 19px solid #249CF3;
    margin-left: 0px;
    margin-right: 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    /* padding: 0px 15px; */
}

.border-bottom-container {
    height: 30px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0 auto;
    cursor: pointer;
    padding: 3px
}

.title-result-happcontainer {
    /* padding-left: 15px; */
    margin-bottom: -5px;
    /* margin-top: -5px; */
}

.dark-opacity {
    background: rgba(34, 34, 34, 0.75);
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.blue-border {
    background-color: #249CF3;
}

.grey-border {
    background-color: #A1A1A1;
}

/* .gray-border {
    background-color: #A1A1A1;
} */

.indicator-warning {
    border-right: 17px solid #ffcc15;
}

@media(min-width: 1890px) {
    .indicators-content {
        width: 46%;
    }
}

@media(min-width: 2000px) {
    .indicators-content {
        width: 40%;
    }
}

@media(min-width: 1025px) and (max-width: 1550px) {
    .indicators-content {
        width: 60%;
    }
}

@media(max-width:1024px) {
    .indicators-content {
        width: 98%;
    }
}

@media(max-width: 700px) {
    .col-img-stage img {
        width: 100%;
    }

    .small-score-icon {
        display: block;
    }

    .score-icon {
        display: none;
    }

    .rcv-info-status {
        margin-top: -90px;
    }

    .rcv-info-status p {
        font-size: 13px;
    }

    /* .col-indicator-mobile {
        width: 50%;
        padding: 13px;
        border: 1px solid red

    } */

    .indicator-result p,
    .determination-content p {
        font-size: 10px;
    }

    .determination-content p {
        padding-left: 3px;
    }

    .determination-content {
        padding-left: 22px;
    }

    .indicators-content .scale-mobile {
        transform: scale(0.7);
        -ms-transform: scale(0.7);
        -webkit-transform: scale(0.7);
        transform-origin: center center;
    }

    .content-score img {
        width: 100%;
    }

    .rcv-alto {
        margin-left: 24px;
    }

    .rcv-bajo {
        margin-left: -48px;
    }
}


@media(max-width: 480px) {
    .risk-bar {
        width: 370px;
    }
}

@media(max-width:340px) {
    .stages {
        width: 300px;
    }
}



/* HomeMessage */

.btn-home-message {
    background-color: #249CF3 !important;
    border-color: #249CF3 !important;
    border-radius: 20px;
    width: 250px;
    font-size: 17px;
    box-shadow: 0px 9px 16px -3px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0px 9px 16px -3px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0px 9px 16px -3px rgba(0, 0, 0, 0.7);
}

.blue-stripe {
    overflow: hidden;
}

.title-message {
    /* font-family: 'Almarai', sans-serif; */
    font-size: 22px;
    /* line-height: 27px; */
    font-weight: 900;
}

.subtitle-message {
    font-size: 14px;
    letter-spacing: 0.08em;
    /* line-height: 16px; */

}

.canvas-message {
    color: white;
    text-align: center;
    letter-spacing: 0.06em;
}

@media(max-width:700px) {
    .blue-stripe {
        overflow: visible;
    }

    .canvas-title {
        width: 660px;
    }

    .canvas-message {
        color: white;
        text-align: center;
        font-size: 14px;
        letter-spacing: 0.06em;
    }
}

/* Modal Indicadores */



.modal-header {
    /* display: flex;
    justify-content: center; */
    /* text-align: center; */
    /* float: ; */
    /* width: 100%; */
    /* border: 1px solid red; */
}

/* .modal-backdrop {
    background-color: transparent;
} */
.modal-content {
    background-color: transparent;
}


.modal-content-blue {
    border-radius: 7px;
    background-color: #04477A !important;
    border-color: #04477A !important;
}

.modal-content-gris {
    background-color: #A1A1A1 !important;
    border-color: #A1A1A1 !important;
    border-radius: 7px;
}

.modal-content-white {
    border-radius: 7px;
    background-color: #fff !important;
    border-color: #fff !important;
}

@media (max-width: 700px) {
    .modal-confirm-consent .modal-content {
        width: 95%;
        margin: 0 auto;
    }
}

.modal-terms {
    background-color: white;
}

.btn-blue {
    background-color: #249CF3 !important;
    border-color: #249CF3 !important;
    border-radius: 8;
    width: 90%
}

.btn-blue-back-travel {
    background-color: #249CF3 !important;
    border-color: #249CF3 !important;
    border-radius: 8;
    width: 310px
}

.btn-blue-schedule {
    background-color: #249CF3 !important;
    border-color: #249CF3 !important;
    border-radius: 8;
    width: 100%;
    /* margin-top: 100px; */
    margin: 40px auto;
}

.btn-gray-schedule {
    filter: grayscale(100%);
    border-radius: 8;
    width: 100%;
    /* margin-top: 100px; */
    margin: 40px auto;
}

.modal-close-btn {
    /* float: right; */
    cursor: pointer;
    /* margin-right: 10px;
    margin-top: 10px; */
    position: absolute;
    right: 10px;
    top: 5px;
}

.btn-initial-check {
    text-decoration: none;
    background-color: transparent;
    color: inherit;
    border: none;
}

.modal-content-tape-result {
    padding-left: 40px
}

.modal-tape-result {
    font-size: 35px;
    font-weight: bold
}

.modal-unit-of-measurement {
    font-size: 12px;
    margin-top: -10px;
    font-weight: 300
}

.title-modal {
    font-family: 'Almarai', sans-serif;
}

.modal-info {
    margin-top: -10px;
    color: white;
    text-align: center;
    /* width: 100%; */
}


.p-modal {
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.03em;
}

.width-info-modal {
    width: 250px;
}

.description-modal-exam {
    color: white;
    text-align: start;
    line-height: 18px;
}

.bar-stage-info {
    width: 150px;
}

.status-bar-normal {
    height: 25px;
    border: 1px solid #249cf3;
    background-color: #249cf3;
}

.status-bar-reddit {
    height: 25px;
    border: 1px solid #fa6200;
    background-color: #fa6200
}

.status-bar-danger {
    height: 25px;
    border: 1px solid red;
    background-color: red
}

.status-bar-warning {
    height: 25px;
    border: 1px solid #ffcc15;
    background-color: #ffcc15
}

.status-bar-success {
    height: 25px;
    border: 1px solid #00A341;
    background-color: #00A341
}


.item-table-modal {
    font-size: 16px;
    /* margin-top: 30px; */
    /* border: 1px solid red */
    /* padding-bottom: 20px; */
}

.bar-table-modal {
    /* padding-bottom: 10px; */
    /* margin-bottom: 20px; */
    /* padding-bottom: 10px; */
    /* margin-bottom: 5px; */
    /* vertical-align: sub; */
    /* display: flex;
    align-content: center; */
    /* width: 90px; */
}

@media (max-width: 335px) {
    .bar-table-modal {
        width: 70px;
        padding-bottom: 10px;
        /* display: flex; */
        /* align-self: center; */
        /* margin-bottom: 10px; */
    }
}

@media (max-width: 435px) {
    .item-table-modal {
        font-size: 12px;
    }
}


/* Agendar Chequeo Diagnostico */
.selectors {
    width: 50%;
}

.modal-icon {
    margin-right: 50px;
}

/* @media (max-width: 575px) {
    .modal-content-tape-result{
        padding-left: 0px
    }
} */

@media (max-width: 750px) {
    .modal-icon {
        margin-right: 0;
    }
}


@media (max-width: 1024px) {
    .selectors {
        width: 85%;
    }
}

/*Indicadores*/
/* MedicalAgenda */
.determinations-list {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.determinations-list .accordion-determinations {
    width: 50%;
    margin: 0 auto;
}

.determinations-list .accordion-determinations .accordion-item {
    border: none;
}

.determinations-list .accordion-determinations .accordion-header .accordion-button {
    background-color: #044064;
    color: #ffffff !important;
    border-radius: 8px;
}

.determinations-list .accordion-determinations .accordion-header .accordion-button::after {}

.determinations-list .accordion-determinations .content-determination {
    background: #F6F6F6;
    border-radius: 8px;
    padding: 15px;
}

.determinations-list .accordion-determinations .content-determination p:first-child {
    text-transform: capitalize;
}

.determinations-list .accordion-determinations .content-determination p {
    margin: 0;
}

.program-content .title {
    font-weight: 700;
    font-size: 18px;
}

.program-content .legend-title {
    font-weight: 400;
    font-size: 16px;
    width: 60%;
    margin: 0 auto;
}

@media (max-width: 700px) {
    .determinations-list .accordion-determinations {
        width: 100%;
    }

    .program-content .legend-title {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
    }
}

/* MedicalAgenda Success */

.btn-success-calendarhr {
    background-color: #249CF3;
    height: 200px;
    width: 200px;
    border-radius: 200px;
    padding-top: 35px;
    padding-left: 35px
}

.btn-time-success-calendar {
    /* border-radius: 8px; */
    width: 150px;
}

.btn-graytime-success-calendar {
    /* border-radius: 8px; */
    filter: grayscale(100%);
    width: 150px;
}

/* TimeSuccessCalendar */
.p-success-schedule {
    line-height: 5px;
}

.btn-homegray-message {
    filter: grayscale(100%);
}

/* exams modal */
.accordion-modal {
    width: 90%;
    /* position: fixed; */
    margin: 0 auto;

}

/* 
.modal3 {
    max-height: 650px;
    width: 100vw;
    overflow-y: scroll; 
    overflow-x: hidden;
    outline: 0;
    position: absolute;
    bottom: 0;
    left: 0; 
    z-index: 99;
    
} */

/* .modal-scroll-mobile{
    
} */

@media (max-width: 700px) {
    .modal3 {
        max-height: 550px;
        /* width: 100vw; */
        /* overflow-y: scroll;  */
        /* overflow-x: hidden; */
        outline: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 99;

    }

    .modal-scroll-mobile {
        height: 300px;
        overflow-y: scroll;
    }
}

/* .modal3{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    position: fixed;
    bottom: 0;
    left: 0; 

    en celulares 
    width: 75%;
    height: 600px;
     outline: 0;
    position: fixed;
    bottom: 0;
    left: 0px;  
} */

.accordion-modal .accordion-header .accordion-button {
    background-color: #E7E7E7;
    color: #000000 !important;
    height: 100px;
    align-items: center;
    /* border-radius: 7px; */
    border-left: 60px solid #044064;
}

.accordion-modal .accordion-header h6 {
    /* height: 100%; */
    align-items: flex-end;
}

.accordion-description {
    font-size: 14px;
    line-height: 16px;
}

.download-icon {
    margin: 5px
}

tr {
    border-top: hidden;
}

tbody {
    border-bottom: hidden;
}


/*Exams*/
.accordion-exams {
    width: 75%;
    margin: 0 auto;
}

.accordion-exams .accordion-header .accordion-button {
    background-color: #044064;
    color: #ffffff !important;
    border-radius: 8px;
    padding: 2.25rem;
}

.special-border-danger .accordion-button {
    border-right: 17px solid #FA0F00;
}

.special-border-success .accordion-button {
    border-right: 17px solid #00A341;
}


.special-border-reddit .accordion-button {
    border-right: 17px solid #fa6200;
}

.special-border-warning .accordion-button {
    border-right: 17px solid #ffcc15;
}

.accordion-exams .accordion-item {
    border: none;
}

.accordion-exams .accordion-body {
    padding: 0px 0.7rem;
}

.accordion-exams .content-exam {
    background: #F6F6F6;
    border-radius: 8px;
    padding: 15px 35px;
    max-height: 300px;
    overflow-y: scroll;
}

.accordion-exams .content-exam .title {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.accordion-exams .calendar-icon {
    margin-right: 15px;
    margin-top: -4px;
}

.accordion-exams .down-icon {
    margin-left: 15px;
    margin-top: -4px;
}

.accordion-exams .content-exam .list-group-item {
    background-color: transparent;
}

.accordion-exams .content-exam .list-group-item .last-col svg {
    float: right;
}

.accordion-exams .icon-header {
    margin-right: 15px;
}

.accordion-exams .content-exam .list-group .row-list div:first-child {
    display: flex;
}

.accordion-exams .content-exam .list-group .row-list div:first-child svg {
    margin-top: 0;
}

.accordion-exams .content-exam::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.accordion-exams .content-exam::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.accordion-exams .content-exam::-webkit-scrollbar-thumb {
    background: #888;
}

.icon-col {
    visibility: hidden;
}

/* Handle on hover */
.accordion-exams .content-exam::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media(max-width:700px) {
    .accordion-exams {
        width: 100%;
    }

    .accordion-exams .content-exam {
        padding: 15px 15px;
    }

    .accordion-exams .accordion-header .accordion-button {
        padding: 8px 12px
    }

    .accordion-exams .content-exam .list-group-item p {
        font-size: 13px;
        margin: 0;
    }

    .accordion-exams .accordion-header .accordion-button svg {
        transform: scale(0.6);
        -ms-transform: scale(0.6);
        -webkit-transform: scale(0.6);
        transform-origin: center center;
    }

    .accordion-exams .content-exam p {
        font-size: 14px;
    }

    .accordion-exams .calendar-icon {
        transform: scale(0.6);
        -ms-transform: scale(0.6);
        -webkit-transform: scale(0.6);
        transform-origin: center center;
    }

    .accordion-exams .content-exam .list-group-item .calendar-icon {
        margin-right: -2px;
        margin-top: 0;
    }

    .accordion-exams .content-exam .list-group-item .mobile-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
}

/*Exams*/
/*Profile*/
.content-profile-form {
    width: 75%;
    margin: 0 auto;
}

.terms-col {
    cursor: pointer;
}

@media (max-width: 700px) {
    .content-profile-form {
        width: 100%;
    }

    .content-profile-form .mb-3 {
        margin-bottom: 0 !important;
    }

    .content-profile-form .my-1 {
        margin-top: 1.25rem !important;
        margin-bottom: 0.25rem !important;
    }
}

/*Profile*/
/*Advices*/
.carousel-box {
    margin: 0 auto;
}

.description-content {
    padding: 0px 50px;
    margin: 0;
    margin-bottom: 20px;
}

.description-content:first-child {
    padding-top: 20px;
}

.carousel-paper {
    padding-left: 0 !important;
    display: block !important;
    height: auto !important;
}

.description-row {
    background: #F6F6F6;
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: block;
    padding-bottom: 1px;
}

.description-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.description-content .list-group .list-group-item {
    background: transparent;
    border: none;
    padding: 0;
    cursor: inherit;
    font-size: 13px;
    padding-left: 30px;
}

.description-content .list-group .list-group-item::before {
    content: "\2022";
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.mt-content-carousel {
    margin-top: 6.5rem !important;
}

.icon-back-content {
    width: 800px;
    margin: 0 auto 20px auto;
}

.icon-back-content .cursor-back {
    cursor: pointer;
    float: left;
    margin-bottom: 20px;
}

.icon-back-content span {
    margin-left: 15px;
}

@media (max-width: 700px) {
    .description-content {
        padding: 0 15px;
    }
}

/*Advices*/
/*Medical Goals*/
.icon-self-registration {
    text-align: center;
}

.col-self-registration {
    width: 75%;
    margin: 0 auto;
}

.input-self-registration {
    margin-top: 0.67rem !important;
}

.input-self-registration input {
    text-align: center;
}

.btn-add-self-registration {
    width: 100%;
}

.accordion-self-registration {
    width: 50%;
    margin: 0 auto;
    margin-top: 1.3rem !important;
    border-radius: 8px;
}

.accordion-self-registration .list-group-item {
    background-color: transparent;
    border: none;
}

.accordion-self-registration .accordion-header .accordion-button {
    background-color: #044064;
    color: #ffffff !important;
    border-radius: 8px;
}

.accordion-self-registration .accordion-item {
    border: none;
}

.row-list-self-registration {
    margin: 0;
}

.row-list-self-registration .mobile-col {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
}

.row-list-self-registration .mobile-col:first-child {
    align-items: center;
    justify-content: start;
    padding-left: 5px;
}

.row-list-self-registration .mobile-col:last-child {
    align-items: center;
    justify-content: end;
    padding-right: 5px;
}

.row-list-self-registration .mobile-col p {
    font-size: 12px;
    margin-left: 4px;
    margin-top: 2px;
    margin-bottom: 0;
}

.accordion-self-registration .list-group {
    background: #F6F6F6;
    border-radius: 8px;
    padding: 0;
}

.inputs-pl {
    display: flex;
    margin-top: -18px;
}

.inputs-pl p {
    text-align: center;
    margin: 0;
    font-size: 12px;
}

.msg-error-input {
    text-align: center;
    background: #FA0F00;
    color: #fff;
    border-radius: 8px;
    margin-top: 5px;
}

@media (max-width: 700px) {
    .col-self-registration {
        width: 100%;
    }

    .header-self-registration {
        align-items: center;
        justify-content: end;
    }

    .accordion-self-registration {
        width: 100%;
    }

    .accordion-self-registration .accordion-header .accordion-button {
        font-size: 10px;
    }

    .btn-add-self-registration {
        font-size: 12px;
    }

    .input-self-registration {
        margin-top: 0.4rem !important;
    }

    .row-list-self-registration {
        margin: 0;
    }

    .row-list-self-registration .mobile-col {
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
    }

    .row-list-self-registration .mobile-col:first-child {
        align-items: center;
        justify-content: start;
        padding-left: 5px;
    }

    .row-list-self-registration .mobile-col:last-child {
        align-items: center;
        justify-content: end;
        padding-right: 5px;
    }

    .row-list-self-registration .mobile-col p {
        font-size: 12px;
        margin-left: 4px;
        margin-top: 2px;
        margin-bottom: 0;
    }

    .accordion-self-registration .list-group {
        background: #F6F6F6;
        border-radius: 8px;
        padding: 0;
    }
}

.border-require {
    border: 2px solid red !important;
}

/*Medical Goals*/

.error-p-medismart {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 3px;
    width: 80%;
    margin: 0 auto;
}

.box-indicator-diagnosis {
    height: 85px;
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 18px;
    font-weight: bold; */
}

.box-indicator-meta {
    margin: 15px 20px !important;
    display: inline-flex;
    width: 41% !important;
    height: 100px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.alert-meta{
    position: absolute;
    right: -23px;
    top: -14px;
    width: 55px !important;
}

.table-striped tbody, td, tfoot, th, thead, tr{
    /* padding: 5px; */
}

@media(max-width: 700px) {
    .box-indicator-meta {
        margin: 10px 14px !important;
        width: 41% !important;
    }
    .table-exams-content{
        padding: 10px 10px !important;
    }
    .table-exams-content .table-exams{
        font-size: 11px !important;
    }
    .table-striped tbody, td, tfoot, th, thead, tr{
        padding: .1rem .3rem !important;
    }
}

@media(min-width: 319px) and (max-width: 370px) {
    .table-striped tbody, td, tfoot, th, thead, tr {
        padding: .1rem 0.1rem !important;
    }
    .box-indicator-meta {
        margin: 10px 14px !important;
        width: 39% !important;
    }
}

@media(min-width: 700px) {
    .box-indicator-diagnosis {
        height: 96px;
        width: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        /* font-weight: bold; */
    }
    /* .box-indicator-meta {
        display: flex;
        width: 80%;
        margin: 15px 20px !important;
        height: 100px;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    } */
}

.border-bottom-container-diagnosis {
    height: 25px;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0 auto;
    cursor: pointer;
    bottom: 0;
}

.nav-title-tabs {
    font-size: 15px;
}

@media(max-width: 436px) {
    .nav-title-tabs {
        font-size: 10px;
    }
    .nav-item a{
        padding: 10px
    }
}

.treatment-icon-content {
    padding: 15px;
}

/* .client-logo {
    height: 120px;
  } */

.custom-list {
    list-style-type: none;
    padding-left: 0;
}

.custom-list li {
    /* list-style-image: url(../../happ-icons/icons.js#HappHeartIconList); */
    padding-left: 20px;
}

.oauth-client-logo {
    display: flex;
    outline: 3px solid #044064;
    margin-left: 5px;
    border: 8px solid #fff;
    border-radius: 4px;
    box-shadow: -4px 6px 12px 2px rgba(0,0,0,0.45);
    z-index: 1;
    box-sizing: content-box;
    max-height: 37px !important;

}

.oauth-loading {
    background-color: #fff;
    height: 100vh !important;
    width: 100vw;
    position: absolute;
    z-index: 1020;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.oauth-loading__logo {
    max-width: 250px;
}
.oauth-loading__spinner {
    width: 25px;
    height: 25px;
    border: #00A0E0;
    border-width: 5px;
    border-style: dotted;
    border-radius: 100%;
}

.rotate-center {
	-webkit-animation: rotate-center 2s ease-in-out infinite both;
	        animation: rotate-center 2s ease-in-out infinite both;
}

@-webkit-keyframes rotate-center {
0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@keyframes rotate-center {
0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}


/*Survey*/
.col-question{
    padding: 20px;
}

.col-question .select-question{
    margin-left: 25px;
    cursor: pointer;
}

.btn-blue-survey {
    background-color: #249CF3 !important;
    border-color: #249CF3 !important;
    border-radius: 8;
    width: 50%;
    /* margin-top: 100px; */
    margin: 40px auto;
}

.btn-grey-survey, .btn-grey-survey:active {
    background-color: #ffffff !important;
    color: #666666 !important;
    border-color: #666666 !important;
    border-radius: 8px;
    padding: 10px;
    /* margin-top: 100px; */
    /* margin: 40px auto; */
}

.col-btns button:first-child{
    margin-right: 5px;
}
.col-btns button:last-child{
    margin-left: 5px;
}

.progress-bar-content{
    margin: 35px auto 20px auto;
}

.content-error-survey{
    margin: 0 auto;
    text-align: center;
}

.row-error{
    margin: 0 !important;
}

.content-error-survey p {
    font-size: 19px;
    font-weight: bold;
    border-bottom: 1px solid;
    color: #e45f5f;
}

.result-content{
    margin: 0 auto;
}

.result-text{
    position: relative;
}


.result-icon svg{
    width: 95%;
}

@media (max-width: 700px) {
    
    .result-text::before{
        content: "• ";
        position: absolute;
        left: -24px;
        color: #04477A;
        font-size: 60px;
        top: -43px;
    }

    .result-text{
        margin-left: 23px
    }
}

/*Survey*/

.content-msg-pathology{
    display: flex;
    margin: 15px auto;
}

.content-msg-pathology p{
    font-weight: bold;
}

.content-msg-pathology svg{
    margin: -15px 0px 0px 25px;
}

.slogan{
    font-weight: bold;
}

.row-bold td{
    font-weight: bold;
}

@media (max-width: 700px) {
    .content-msg-pathology svg{
        margin: -15px 0px 0px 15px;
        transform: scale(0.7);
        -ms-transform: scale(0.7);
        -webkit-transform: scale(0.7);
        transform-origin: center center;
    }
}

.tbody-meta tr td{
    border: none;
    text-align: center !important;
}

.padding-0{
    padding: 0 !important;
}

.primary-bg-color-white-href, .primary-bg-color-white-href:focus{
    background-color: transparent;
    color: #044064;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
}

.primary-bg-color-white-href:hover {
    border-radius: 7px;
    background-color: transparent;
    border: 1px solid;
    color: #044064;
}

.btn-options{
    margin: 0 10px 0 10px;
}

@media (max-width: 700px) {
    .btn-options{
        margin: 0 5px 0 5px;
    }   
}

.consent-card-title {
    font-family: 'Almarai', sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin-top: 20px;
    margin-left: 16px;
    color: #555;
}

.pdf-preview-btn{
    margin-left: 6px;
    font-size: 13px;
    font-weight: 600;
}

.pdf-preview-btn:hover{
    color: #00A0E0;
}

.underline {
    position: relative;
    color: #00A0E0;
    text-underline-offset: 2px;
  }

.pdf-slogan {
    padding-left: 16px;
    padding-right: 16px;
}

.consent-card-text {
  font-family: 'Almarai', sans-serif;
  font-size: 13px;
  text-align: left;
  color: #555;
}

.consent-card-samll-text {
    font-family: 'Almarai', sans-serif;
  font-size: 10px;
  text-align: left;
  font-weight: bold;
  color: #414141;
}

.consent-input-text {
    font-family: 'Almarai', sans-serif;
    font-size: 14px;
    text-align: left;
    max-width: 90%;
}

.consent-input-text-validation-code {
    max-width: 50% !important;
    margin-right: 20px;
}

.consent-link-text {
    font-family: 'Almarai', sans-serif;
    font-size: 12px;
    text-align: left;
    color: #249CF3;
}

.consent-input-code-box {
    width: 150px; /* Adjust width as needed */
}

.consent-button {
    color:#ffffff;
    background-color: #044064;
    border-color: #044064; /* Indigo color */
    cursor: pointer;
    margin-left: 6px;
    border-radius: 0.25rem;
    font-size: 12px;
    font-family: 'Almarai', sans-serif;
    font-weight: bold;
    max-height: 40px;
}

.consent-button-reject {
    color:#ffffff;
    background-color: #FA0F00;
    border-color: #FA0F00; /* Indigo color */
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: 12px;
    font-family: 'Almarai', sans-serif;
    font-weight: bold;
    width: 46px;
    height: 32px;
}

.consent-button-reject:hover {
    color:#ffffff;
    background-color: #963109; 
    border-color: #963109; /* Dark Indigo color */
}


.consent-button-confirm {
    color:#ffffff;
    background-color: #00A341;
    border-color: #00A341; /* Indigo color */
    cursor: pointer;
    margin-left: 6px;
    border-radius: 0.25rem;
    font-size: 12px;
    font-family: 'Almarai', sans-serif;
    font-weight: bold;
    width: 46px;
    height: 32px;
}

.consent-button-confirm:hover {
    color:#ffffff;
    background-color: #095e17; 
    border-color: #095e17; /* Dark Indigo color */
}

.consent-button:hover {
    color:#ffffff;
    background-color: #044064; 
    border-color: #044064; /* Dark Indigo color */
}

.underline {
    text-decoration: underline;
  }

.consent-button {
    z-index: 0 !important;
}

/* width */
.card-body-pdf::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.card-body-pdf::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.card-body-pdf::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.card-body-pdf::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.reduced-card-width {
    max-width: 400px; 
    margin: 0 auto; 
}

.consent-input-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-top: 10px;
}

.pdf-container {
    padding: 5px;
    background-color: #F6F6F6;
    justify-content: center;
  }
  
canvas.react-pdf__Page__canvas {
    margin-bottom: 10px;
}

.modal-content {
    font-family: 'Almarai', sans-serif;
    background-color: white !important;
}

.modal-consent-button {
    color:#ffffff;
    background-color: #044064;
    border-color: #044064;
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: 14px;
    font-family: 'Almarai', sans-serif;
    font-weight: bold;
}

.react-pdf__Page__canvas{
    width: 100% !important;
    height: auto !important;
}

.table-exams-content{
    padding: 15px;
}

.table-exams-content .table-exams{
    font-size: 12px;
}

.table-exams>tbody{
    vertical-align: middle !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>*{
    --bs-table-accent-bg: rgba(199, 223, 240, 0.28);
}

.progress-bar-spinner{
    width: 90px;
    height: 90px;
    margin: 0 auto;
}

.spinner-loading-days{
    width: 1.5rem;
    height: 1.5rem;
}

.cancer-icon-img {
    width: 45px;
}    
.content-bar-bp{
    width: 120px;
}

.content-bar-bp img {
    width: 100%;
}

@media(max-width: 700px) {
    .content-bar-bp {
        width: 95px;
    }
}