.preloader-view {
    display: flex;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    margin: 100px auto;

    .loader-element-view {
        transition:(spin .3s linear infinite);
        -webkit-animation:spin 3s linear infinite;
        -moz-animation:spin 3s linear infinite;
        animation:spin 3s linear infinite;
    }

    &.show-view {
        height: 0;

        .loader-element {
            display: none !important;
        }
    }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }