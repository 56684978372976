.content-surveys {
    margin: 0 auto;
	position: relative;
}
.body-table-content {
    padding: 1.5rem 1.5rem !important;
}
.btn-save-survey {
    float: right;
}
.header-accordion-survey {
    display: flex;
}
.header-accordion-survey img {
    width: 32px;
    height: 32px;
}
.header-accordion-survey h6 {
    margin: 0 !important;
    vertical-align: middle;
    line-height: 2.5;
    margin-left: 15px !important;
}
.accordion-button {
    height: 60px;
}
.survey-select-question input {
    border: 1px solid #2f3237;
}

.disabled input {
    background-color: #abaeb2;
    border: 1px solid #abaeb2;
}

.showQuestion {
    display: flex !important;
}

.hideQuestion {
    display: none !important;
}

.arrow-steps .step {
	font-size: 14px;
	text-align: center;
	color: #666;
	cursor: default;
	margin: 0 3px;
	padding: 10px 0px 10px 30px;
	min-width: 10px;
    height: 32px;
	float: left;
	position: relative;
	background-color: #d9e3f7;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; 
    transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
	content: " ";
	position: absolute;
	top: 0;
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 17px solid #d9e3f7;	
	z-index: 2;
    transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
	right: auto;
	left: 0;
	border-left: 17px solid #fff;	
	z-index: 0;
}

.arrow-steps .step:first-child:before {
	border: none;
}

.arrow-steps .step:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}


.arrow-steps .step.current {
	color: #fff;
	background-color: #23468c;
}

.arrow-steps .step.current:after {
	border-left: 17px solid #23468c;	
}

.circle-btn {
	height: 80px !important;  
	width: 80px !important;  
	border-radius: 50% !important;
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.8), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }

  .btn-arrow-back{
	background-color: #fff;
	border: 1px solid #2f3237;
  }

  .btn-outline-danger {
	background-color: #fff !important;
  }

  .content-btn p {
	margin-bottom: 0;
	margin-left: 10px;
  }

  .col-btn-back{
	margin-top: 5rem !important;
  }

  .btn-outline-danger:active, .btn-outline-danger:focus, .btn-outline-danger:hover {
	color: #dc3545 !important;
  }

  .title-results, .article-link {
	color: #044064;
	font-weight: bold;
  }

  .slogan-results {
	color: #0bd2d2;
	font-weight: 550;
  }

  .derivation-list {
	margin-left: 20px;
  }

  .derivation {
	color: #0d6efd;
	font-weight: bold;
	font-size: 18px;
  }

